.employment {
  margin-top: 100px;

  .card {
    margin-bottom: 40px;

    .label {
      position: absolute;
      top: 0;
      left: -16px;
      background: rgba($green, 0.2);
      color: $green;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      border-radius: 100%;
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
    }

    .card-body-small {
      padding: 30px;

      strong {
        color: $dark;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: 60px;

    .cards {
      margin-top: 30px;
      margin-left: 15px;
    }

    .card {
      margin-bottom: 30px;
    }
  }
}
