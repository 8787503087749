//
// Common: Fonts
//
// @author: Rutger Laurman - lemone.com
//

// Font definitions
@font-face {
  font-family: 'Museo Sans';
  font-weight: 500;
  font-style: normal;
  src: url('/assets/fonts/MuseoSans500/font.woff2') format('woff2'),
    url('/assets/fonts/MuseoSans500/font.woff') format('woff');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 700;
  font-style: normal;
  src: url('/assets/fonts/MuseoSans700/font.woff2') format('woff2'),
    url('/assets/fonts/MuseoSans700/font.woff') format('woff');
}

@font-face {
  font-family: 'Museo';
  font-weight: 500;
  font-style: normal;
  src: url('/assets/fonts/Museo500/font.woff2') format('woff2'),
    url('/assets/fonts/Museo500/font.woff') format('woff');
}

@font-face {
  font-family: 'Museo';
  font-weight: 700;
  font-style: normal;
  src: url('/assets/fonts/Museo700/font.woff2') format('woff2'),
    url('/assets/fonts/Museo700/font.woff') format('woff');
}
