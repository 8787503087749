@import 'animate.css/animate.min.css';

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

h1,
.h1 {
  font-size: 48px;
  line-height: 60px;
  font-weight: 700;
  letter-spacing: -0.8;
  color: $dark;
}

h2,
.h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: -0.5;
  color: $dark;
  margin-bottom: 20px;
}

p {
  margin-bottom: 35px;
}

img {
  max-width: 100%;
}

.equal-height {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.wrapper {
  position: relative;
  overflow: hidden;
  transition: filter 500ms ease;
}

.btn.btn-primary {
  background-color: $dark;
  border-radius: 21.5px;
  box-shadow: 0 10px 16px -10px rgba($dark, 0.5);
  border: none;
  outline: none;
  padding: 8px 18px;
  transform: scale(1);
}

.btn.btn-secondary {
  background-color: $white;
  color: $dark;
  border-radius: 21.5px;
  box-shadow: 0 10px 16px -10px rgba($dark, 0.5);
  border: 2px solid $dark;
  outline: none;
  padding: 8px 18px;
  transform: scale(1);
  font-weight: 700;

  svg {
    transition: transform 300ms ease;
    width: 13px;
    margin: 2px 0 2px 10px;
  }

  &:focus:not(:disabled):not(.disabled) {
    box-shadow: 0 10px 16px -10px rgba($dark, 0.5);
  }

  &:active:not(:disabled):not(.disabled) {
    border-color: $dark;
    background-color: $dark;
    color: $white;

    svg path {
      fill: $white;
    }
  }
}

.parallax {
  transition: transform 10ms ease;
}

.chart-wrapper {
  background: rgba($white, 0.2);
  padding: 40px;
  border-radius: 20px;
  margin-bottom: 25px;

  p {
    margin-bottom: 0;
  }

  label {
    color: $white;
    font-size: 20px;
    display: block;
    font-weight: 700;
  }

  span {
    color: $white;
    display: inline-block;
    margin-right: 10px;
  }

  canvas {
    margin-top: 20px;
  }
}

@include media-breakpoint-down(md) {
  body {
    font-size: 14px;
    line-height: 24px;

    &.menu-open {
      height: 100vh;
      overflow: hidden;
    }
  }

  h1,
  .h1 {
    //
  }

  h2,
  .h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: -0.38;
    color: $dark;
  }

  p {
    font-size: 14px;
    line-height: 24px;
  }

  .wow {
    animation-delay: 0ms !important;
  }

  .chart-wrapper {
    padding: 25px 15px;
  }
}
