.footer {
  background-image: url('../images/gradient-3.png');
  background-size: 100% 130%;
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-top: 500px;
  margin-top: -330px;

  .logo {
    display: block;

    &.pt-1 {
      height: 59px;
      margin-bottom: 15px;
    }

    &.pt-2 {
      height: 45px;
    }
  }

  &__bottom {
    margin: 95px 0;
    display: flex;
    justify-content: space-between;
    color: rgba($white, 0.7);
    font-size: 14px;

    svg {
      height: 20px;
    }
  }

  @include media-breakpoint-down(md) {
    background-size: 190%;

    .logo {
      &.pt-1 {
        height: 35px;
        margin-bottom: 10px;
      }

      &.pt-2 {
        height: 27px;
      }
    }

    &__bottom {
      margin: 25px 0 30px 0;
    }
  }
}
