//
// Common: variables: fonts
//
// @author: Rutger Laurman - lemone.com
//

$font-museo-sans: 'Museo Sans', sans-serif;
$font-museo: 'Museo', sans-serif;

$font-family-base: $font-museo-sans;
$headings-font-family: $font-museo;
$font-size-base: 18px;
$line-height-base: 32px;
$body-color: $gray;

// giga
// mega
// kilo

// alpha
// beta
// gamma
// delta
// epsilon
// zeta
//

// $font-size-base: (18 / 16) + rem;
// $line-height-base: (25 / 18);
//
// $headings-line-height: (65 / 48);
// $headings-font-weight: bold;
//
// $font-size-h1: (48 / 16) + rem;
// $font-size-h2: (30 / 16) + rem;
// $font-size-h3: (24 / 16) + rem;
// $font-size-h4: 1.2rem;
// $font-size-h5: 1.1rem;
// $font-size-h6: 1rem;
