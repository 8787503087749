$card-spacer-y: 70px;
$card-spacer-x: 80px;

$card-border-width: 0;
$card-border-radius: 20px;
$card-border-color: transparent;

.card {
  box-shadow: 0 20px 90px -30px rgba($purple, 0.4);
}

@media (max-width: 991px) {
  // TODO Vragen aan @rutger9321 hoe om te gaan met verschillende variabelen met breakpoints

  .card {
    border-radius: 10px !important;
  }

  .card .card-body {
    padding: 30px;
  }
}
