.wave {
  position: absolute;
  z-index: -1;
  height: 715px;
  width: 667px;

  &.wave-1 {
    margin-top: -220px;

    @include media-breakpoint-down(md) {
      margin-top: -100px;
      transform: scaleX(-1);
      width: 345px;
      right: -50px;
    }
  }

  &.wave-2 {
    margin-top: -700px;
    transform: rotate(10deg) scaleY(-1);

    @include media-breakpoint-down(md) {
      margin-top: -490px;
    }
  }

  &.wave-3 {
    width: 740px;
    right: -30px;
    margin-top: -235px;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &.wave-4 {
    left: 52vw;
    margin-top: -265px;

    @include media-breakpoint-down(md) {
      left: -10px;
      margin-top: -180px;
      width: 115vw;
    }
  }
}
