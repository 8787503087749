//
// Common: variables: colors
//
// @author: Rutger Laurman - lemone.com
//

$white: #fff;
$borderGray: #c4c4c8;
$gray: #9393a7;
$pink: #f6abb5;
$purple: #6b1e74;
$dark: #26264e;
$green: #82bc00;
$light-green: #e5eba2;

$gradient-green: linear-gradient(180deg, #f6f5ab 0%, $green 100%);

// $brand-primary: $outrageous-orange;
// $brand-success: $medium-sea-green;
