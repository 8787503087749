.partnership {
  margin-top: 100px;

  .section-intro {
    margin-top: 20px;
  }

  &__logos {
    position: relative;
    height: 500px;

    &::before {
      content: '';
      width: 480px;
      height: 480px;
      top: 30px;
      right: -61px;
      border-radius: 100%;
      background: $gradient-green;
      position: absolute;
      opacity: 0.1;
    }

    .logo {
      position: absolute;
      display: block;
      background-color: red;
      border-radius: 100%;
      display: flex;
      box-shadow: 0 10px 24px -8px rgba($purple, 0.2);

      img {
        margin: auto;
      }

      &.schulden-aanpakken {
        background-color: $purple;
        height: 100px;
        width: 100px;
        top: 110px;
        left: 145px;

        img {
          width: 77px;
        }
      }

      &.kredietbank {
        background-color: $white;
        height: 109px;
        width: 109px;
        top: -10px;
        left: 230px;

        img {
          width: 86px;
        }
      }

      &.nvvk {
        background-color: #39498a;
        height: 109px;
        width: 109px;
        top: 110px;
        right: 5px;

        img {
          width: 78px;
        }
      }

      &.schulden-wijzer {
        background-color: #cde0e3;
        height: 91px;
        width: 91px;
        bottom: -40px;
        right: 95px;

        img {
          width: 78px;
        }
      }

      &.nsr {
        background-color: $white;
        height: 113px;
        width: 113px;
        bottom: 40px;
        left: 45px;

        img {
          width: 78px;
        }
      }

      &.schuldenknooppunt {
        background-color: #f3f8f9;
        height: 140px;
        width: 140px;
        top: 113px;
        left: -25px;

        img {
          width: 106px;
        }
      }

      &.geldfit {
        background-color: #fff;
        height: 100px;
        width: 100px;
        bottom: 95px;
        right: 0px;

        img {
          width: 80px;
        }
      }

      &.syncasso {
        background-color: #82bc00;
        height: 120px;
        width: 120px;
        top: 245px;
        left: 175px;

        img {
          padding-top: 4px;
          width: 94px;
        }
      }
    }
  }

  &__usps {
    margin-top: 75px;
  }

  &__quote {
    margin-top: 75px;

    .card {
      background: radial-gradient(circle at 120% -100%, $pink 0%, $purple 70%);
      padding-top: 10px;

      .quote {
        position: absolute;
        margin-top: -40px;
        margin-left: -20px;
        width: 155px;
      }

      .card-body {
        padding-bottom: 40px;
      }

      blockquote {
        color: $white;

        footer {
          margin-top: 25px;
          opacity: 0.5;

          &::before {
            content: '';
            display: inline-block;
            margin: 5px 20px 5px 0;
            width: 50px;
            height: 1px;
            background: $white;
          }
        }
      }

      &::after {
        content: '';
        width: calc(100% - 60px);
        height: 15px;
        margin-bottom: -15px;
        margin-left: 30px;
        background-color: #a779ac;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }

  body.stick-menu &,
  body.modal-open & {
    padding-top: 64px;
  }

  @include media-breakpoint-down(lg) {
    body.stick-menu &,
    body.modal-open & {
      padding-top: 61px;
    }

    &__logos {
      .logo {
        &.schulden-aanpakken {
          top: 80px;
          left: 95px;
        }

        &.kredietbank {
          top: 0px;
          left: 190px;
        }

        &.nvvk {
          top: 110px;
          right: 5px;
        }

        &.schulden-wijzer {
          bottom: -40px;
          right: 95px;
        }

        &.nsr {
          bottom: 40px;
          left: -45px;
        }

        &.schuldenknooppunt {
          top: 113px;
          left: -75px;
        }

        &.geldfit {
          bottom: 95px;
          right: 0px;
        }

        &.syncasso {
          top: 245px;
          left: 95px;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: 60px;

    &__logos {
      margin: 30px auto auto auto;
      height: 480px;
      width: 340px;

      &::before {
        width: 100%;
        height: auto;
        width: 340px;
        height: 340px;
        padding: 0;
        margin: auto;
        left: 0;
        right: 0;
      }

      .logo {
        transform: scale(0.75);

        &.schulden-aanpakken {
          top: 50px;
          left: 100px;
        }

        &.kredietbank {
          top: -30px;
          left: 170px;
        }

        &.nvvk {
          top: 90px;
          left: 235px;
        }

        &.schulden-wijzer {
          bottom: 80px;
          left: 155px;
        }

        &.nsr {
          top: 240px;
          left: 30px;
        }

        &.schuldenknooppunt {
          top: 80px;
          left: -25px;
        }

        &.geldfit {
          top: 215px;
          left: 220px;
        }

        &.syncasso {
          top: 155px;
          left: 110px;
        }
      }
    }

    &__usps {
      margin-top: 10px;

      .usp {
        margin-bottom: 15px;
      }
    }

    &__quote {
      .card {
        .quote {
          margin: -10px;
          width: 77px;
        }

        blockquote {
          margin: 20px 10px 0 10px;

          footer {
            &::before {
              width: 25px;
              margin: 4px 10px 4px 0;
            }
          }
        }
      }
    }
  }
}
