.neighbourship {
  margin-top: 126px;
  position: relative;

  .card {
    margin-top: 380px;
  }

  &__image {
    position: absolute;
    background-image: url('../images/foto01.jpg');
    width: calc(100vw - 95px);
    height: 850px;
    background-position: center;
    background-size: 130%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;

    .image-gradient {
      background-size: cover;
      position: absolute;
      right: 0;
      width: 50%;
      height: 100%;

      background-image: url('../images/gradient-1.png');
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: 50px;

    .card {
      margin-top: 205px;
    }

    &__image {
      height: 250px;
      width: 100%;
      border-radius: 0;
    }
  }
}
