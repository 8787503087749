.usp {
  display: flex;

  .icon {
    margin-right: 15px;
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: rgba($green, 0.2);

    svg {
      margin: auto;
      width: 16px;
    }
  }

  .text {
    flex: 1;

    span {
      color: $dark;
      font-weight: 600;
    }
  }
}
