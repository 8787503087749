.greening {
  margin-top: 150px;

  &__image {
    background-image: url('../images/foto-vrouw-2.jpg');
    background-position: center;
    background-size: cover;
    padding-top: 100%;
    width: 100%;
    height: auto;
    border-radius: 100%;
  }

  @include media-breakpoint-down(md) {
    margin-top: 45px;

    &__image {
      width: 100%;
      max-width: 350px;
      height: 350px !important;
      margin: auto;
      padding-top: 0;
    }

    .section-intro {
      margin-top: 30px;
    }
  }
}
