.communication {
  margin-top: 150px;
  background-image: url('../images/communication-background.jpg');
  background-size: 150%;
  background-position: right center;
  padding-top: 140px;
  padding-bottom: 130px;

  .section-intro {
    .icon {
      background-color: $white;
    }
  }

  .women {
    position: absolute;
    display: block;
    bottom: -131px;
    right: 0;
    left: 0;
    margin: auto;
  }

  p,
  h1,
  h2 {
    color: $white;
  }

  .btn-plus {
    background-color: $white;
    animation: pulse-white 2s infinite;

    &::before,
    &::after {
      background-color: $green;
    }
  }

  @media (max-width: 1550px) {
    background-size: 210%;
  }

  @include media-breakpoint-down(md) {
    margin-top: 50px;
    padding-top: 40px;
    padding-bottom: 0;
    background-size: cover;

    .women {
      position: relative;
      bottom: 0;
      max-width: 150px;
      margin-top: -40px;
    }
  }
}
