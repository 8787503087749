//
// Component: button
//
// @author: Rutger Laurman - lemone.com
//

@import '~bootstrap/scss/buttons';

.btn {
  &.btn-plus {
    background-color: $green;
    border-radius: 100%;
    width: 48px;
    height: 48px;
    display: flex;
    position: relative;
    padding: 0;

    box-shadow: 0 0 0 0 rgba($green, 1);
    transform: scale(1);
    animation: pulse 2s infinite;

    &::before,
    &::after {
      position: absolute;
      content: '';
      width: 20px;
      height: 2px;
      background-color: $white;
      border-radius: 5px;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &::after {
      transform: rotate(90deg);
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($green, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 25px rgba($green, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($green, 0);
  }
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($white, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 25px rgba($white, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($white, 0);
  }
}
